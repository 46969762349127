var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$store.state.menu.mainMenu)?_c('Container',{staticClass:"d-print-none",attrs:{"full-viewport-width-wrapper":_vm.$themeSettings.components.MainMenu.fullViewportWidthWrapper,"inner-container-class":_vm.innerContainerClass,"element-class":"MainMenu"},nativeOn:{"mouseenter":function($event){return _vm.loadDropdowns()}}},[_c('div',{staticClass:"MainMenu__content",class:{'MainMenu__content--layoutMenuOverlay': _vm.layoutMenuOverlay},attrs:{"id":"MainMenuContainer"}},[_c('nav',{staticClass:"MainMenu__nav",attrs:{"aria-label":_vm.$t('MainMenu.ariaLabel')}},_vm._l((_vm.$store.state.menu.mainMenu.menuItems),function(item,index){return _c('AppLink',{key:`mainmenubutton${item.id}${index}`,staticClass:"MainMenu__link",class:[
          ...(item.styles ? [..._vm.$themeSettings.components.MainMenu.linkStyles, ...item.styles].map(style => `MainMenu__link--${style}`) : []),
          _vm.activeMenuItems.indexOf(item) !== -1 ? 'MainMenu__link--active' : null],attrs:{"link":item,"id":'menuButton' + index}},[_c('span',[_vm._v(_vm._s(item.title))]),(
            _vm.$themeSettings.components.MainMenu.enableDropdown
              && _vm.$themeSettings.components.MainMenu.showDropdownIndicator
              && item.children && item.children.length > 0 && _vm.mainMenuLinkIcon
          )?_c('span',[_c('svgicon',{staticClass:"MainMenu__linkIcon",attrs:{"icon":_vm.mainMenuLinkIcon,"fill":""}})],1):_vm._e()])}),1),(_vm.$themeSettings.components.MainMenu.enableDropdown)?_vm._l((_vm.$store.state.menu.mainMenu.menuItems),function(item,index){return (item.children && item.children.length > 0 && !_vm.excludedDropdownCategoryIds.includes(String(item.id)))?_c('Dropdown',{key:`dropdown${item.id}${index}`,staticClass:"Dropdown--fullWidth",attrs:{"trigger":'menuButton' + index,"delay-show":250,"delay-hide":250,"options":{ placement: 'bottom-start' },"target":"MainMenuContainer"},on:{"hide":_vm.dropdownHide,"show":_vm.dropdownShow}},[_c('div',{staticClass:"MainMenu__dropdown"},[_c('LazyMainMenuDropdownContent',{attrs:{"heading":item.menu_heading,"items":item.children}})],1)]):_vm._e()}):_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }