import MainMenu from 'themes/base/components/shop/MainMenu'

export default {
  extends: MainMenu,
  data() {
    return {
      excludedDropdownCategoryIds: ['1447'],
    }
  },
}
